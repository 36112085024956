<template>
  <div class="h-full">
    <Scrollbar>
      <div class="w-full">
        <div class="m-4" ref="title">
          <h1 class="school-title text-xl font-semibold text-blue-800">升级设置</h1>
        </div>
        <el-form ref="form" :model="form" label-width="180px">
          <el-form-item label="积分兑换幸福卡比例">
            <el-input-number v-model="form.num1" :min="1" :max="100" label="描述文字"></el-input-number>
          </el-form-item>
          <el-form-item label="幸福卡兑换幸福币比例">
            <el-input-number v-model="form.num2" :min="1" :max="100" label="描述文字"></el-input-number>
          </el-form-item>
          <el-form-item label="幸福币比例">
            <el-input-number v-model="form.num3" :min="1" :max="100" label="描述文字"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </Scrollbar>
  </div>
</template>
<script>
export default {
  name: "conversion",
  data() {
    return {
      form: {
        num1: 20,
        num2: 20,
        num3: 20,
        num4: 20,
        num5: 20,
        num6: 20
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }
};
</script>
<style scoped>

</style>
